@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

.copy-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #b2dffb;
  border-radius: 8px;
  padding: 8px;
}

.copy-text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #0054a4;
  margin-right: 16px;
}

.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: transparent;
  color: #0054a4;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  border: none;
}

.copy-button:hover {
  background-color: #f3faff;
}

.copy-button.copied {
  color: #42a5f5;
}

.copy-icon {
  margin-right: 8px;
}

.qr-code_pagSeguro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.qr-code-margin {
  margin-right: 30px;
}
