.container-prevention {
  text-align: center;
  margin-bottom: 20px;
}

.prevention {
  max-width: 100%;
  height: auto;
}

.container-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

.card {
  width: calc(20% - 20px);
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.card.expanded {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
  .container-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
}

.card.expanded {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .card {
    width: calc(50% - 20px);
  }

  .card.expanded {
    height: none;
  }

  .container-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 560px) {
  .card {
    width: calc(100% - 20px);
  }

  .prevention {
    max-width: 100%;
    height: 120px;
  }

  .container-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }
}
