@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.WhoWeAreContainer {
  display: flex;
  align-items: flex-start;
  padding: 48px;
}

.WhoWeAreContainer .textContainer {
  flex: 1;
  font-family: "PT Sans", sans-serif;
}

.WhoWeAreContainer .textContainer h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.WhoWeAreContainer .textContainer p {
  font-size: 18px;
  font-family: "PT Sans", sans-serif;
  margin-bottom: 20px;
  text-align: justify;
  padding: 0 24px;
  color: #555;
  font-weight: bold;
}

.WhoWeAreContainer .imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WhoWeAreContainer .imageContainer .image {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.WhoWeAreContainer .imageContainer .image img {
  width: 250px;
  height: 150px;
  transition: all 0.3s ease;
}

.WhoWeAreContainer .imageContainer .image:hover img {
  transform: scale(1.2);
}

.WhoWeAreContainer .textContainer .signature {
  font-family: "PT Sans", sans-serif;
  text-align: center;
  font-weight: bold;
  margin-top: 50px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
}

.modalContent {
  position: relative;
  padding: 0;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.modalContent img {
  width: 1000px;
  height: 500px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .WhoWeAreContainer {
    flex-direction: column;
    padding: 24px;
  }

  .WhoWeAreContainer .textContainer {
    order: -1;
    width: 100%;
    margin-right: 0;
  }

  .WhoWeAreContainer .textContainer h1 {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: "PT Sans", sans-serif;
  }

  .WhoWeAreContainer .imageContainer {
    width: 100%;
    flex-direction: column;
  }

  .WhoWeAreContainer .textContainer p {
    font-size: 18px;
    margin-bottom: 15px;
    padding: 0;
    font-family: "PT Sans", sans-serif;
  }

  .WhoWeAreContainer .imageContainer .image {
    width: 100%;
    margin-bottom: 20px;
    pointer-events: none;
  }

  .WhoWeAreContainer .imageContainer .image img {
    width: 100%;
    height: 900px;
    transition: none;
  }

  .modalContent img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .WhoWeAreContainer {
    padding: 16px;
  }

  .WhoWeAreContainer .textContainer {
    order: -1;
    width: 100%;
    margin: 0;
  }

  .WhoWeAreContainer .textContainer h1 {
    font-size: 22px;
    margin-bottom: 15px;
    font-family: "PT Sans", sans-serif;
  }

  .WhoWeAreContainer .textContainer p {
    font-size: 18px;
    margin-bottom: 15px;
    padding: 0;
    font-family: "PT Sans", sans-serif;
  }

  .WhoWeAreContainer .imageContainer .image {
    margin-bottom: 15px;
    pointer-events: none;
  }

  .WhoWeAreContainer .imageContainer .image img {
    width: 100%;
    height: 200px;
    transition: none;
  }

  .modalContent {
    padding: 16px;
  }

  .modalContent img {
    width: 100%;
    height: auto;
  }

  .close {
    font-size: 20px;
  }
}
