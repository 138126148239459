@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.donations-container {
  font-family: "PT Sans", sans-serif;
  text-align: center;
  margin-bottom: 30px;
  padding: 50px 48px 70px 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-section {
  width: 50%;
}

.right-section {
  width: 40%;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.paragraph {
  font-size: 18px;
  color: #555;
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
  line-height: 2.5;
}

.paragraph-whats {
  font-size: 18px;
  color: #555;
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  .donations-container {
    padding: 5px 10px 0 10px;
    flex-direction: column;
    flex-direction: column-reverse;
    font-size: 20px;
  }

  .left-section,
  .right-section {
    width: 100%;
    max-width: 100%;
    margin-top: 25px;
  }

  .title {
    font-size: 20px;
  }

  .paragraph {
    font-size: 16px;
  }
}
