@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.tratament-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 40px 40px 40px;
}

.tratament-header {
  height: 250px;
  width: 100%;
  display: flex;
  opacity: 0.7;
}

.tratament-title {
  font-family: "PT Sans", sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-top: 10px;
}

.tratament-main-title {
  font-family: "PT Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.tratament-description {
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  color: #555;
  margin-bottom: 0px;
  text-align: justify;
}

.tratament-bold {
  font-weight: bold;
}

.tratament-steps {
  display: flex;
  flex-direction: column;
}

.step-title {
  font-family: "PT Sans", sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.step-description {
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  color: #555;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .tratament-container {
    padding: 0 20px;
  }

  .tratament-title {
    font-size: 28px;
    margin-top: 20px;
  }

  .tratament-description {
    font-size: 16px;
  }

  .step-title {
    font-size: 18px;
  }

  .step-description {
    font-size: 16px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .tratament-container {
    padding: 0 15px 20px 15px;
  }

  .tratament-title {
    font-size: 32px;
    margin-top: 20px;
  }

  .tratament-description {
    font-size: 16px;
  }

  .step-title {
    font-size: 18px;
  }

  .step-description {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .tratament-container {
    padding: 0 15px 20px 15px;
  }

  .tratament-title {
    font-size: 30px;
    margin-top: 20px;
  }

  .tratament-description {
    font-size: 20px;
    text-align: justify;
  }

  .step-title {
    font-size: 16px;
  }

  .step-description {
    font-size: 14px;
  }
}
