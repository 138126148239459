@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.installation-container {
  padding: 24px;
  text-align: center;
}

.installation-title {
  font-family: "PT Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.installation-subtitle {
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.photo-gallery img {
  width: 100%;
  height: auto;
  cursor: pointer;
  max-width: calc(25% - 10px);
  transition: transform 0.3s ease;
}

.photo-gallery img:hover {
  transform: scale(1.2);
}

.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.photo-gallery img {
  width: 100%;
  height: auto;
  cursor: pointer;
  max-width: calc(25% - 10px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
}

.modalContent {
  position: relative;
  padding: 0;
  max-width: 1000px;
  max-height: 500px;
  overflow: auto;
}

.modalContent img {
  width: 100%;
  height: auto;
}

.close_modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background: transparent;
  border: none;
}

.close:hover {
  color: #fff;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 54px;
  font-weight: bold;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.carousel-prev {
  left: 10px;
}

.carousel-next {
  right: 10px;
}

/* Responsividades */
@media (max-width: 768px) {
  .installation-container {
    padding: 10px;
  }
  .photo-gallery img {
    max-width: 100%;
    max-height: none;
  }
  .overlay,
  .modal,
  .modalContent,
  .close_modal,
  .carousel-indicators,
  .carousel-prev,
  .carousel-next {
    display: none !important;
  }
  .photo-gallery img:hover {
    transform: none;
  }
}

@media (max-width: 480px) {
  .installation-container {
    padding: 10px;
  }
  .photo-gallery img {
    max-width: 100%;
    max-height: none;
  }
  .installation-subtitle {
    font-size: 20px;
    text-align: justify;
  }
  .installation-title {
    margin-top: -2px;
  }
  .overlay,
  .modal,
  .modalContent,
  .close_modal,
  .carousel-indicators,
  .carousel-prev,
  .carousel-next {
    display: none !important;
  }
  .photo-gallery img:hover {
    transform: none;
  }
}
