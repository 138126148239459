@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

html,
body,
.pages {
  margin: 0;
  padding: 0;
}

.pages {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  justify-content: center;
  height: 65px;
}

header {
  width: 100%;
  background-color: #206ce4;
  margin-bottom: auto;
}

.faixa-amarela {
  background-color: #ffff00;
  width: 100%;
  height: 20px;
}

.menu-label {
  display: inline-block;
  margin-left: 20px;
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 1.8rem;
}

.yellow-bar {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #ffff00;
}

.banner-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #ffff00;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-image {
  width: 200px;
  height: 100px;
}

.logo-image-footer {
  width: 170px;
  height: 90px;
}

header img {
  width: 80px;
  height: 50px;
  margin-right: 0;
  padding: 15px 0;
}

header nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-inline-start: 0;
  margin-inline-start: 0;
}

header nav ul li {
  list-style: none;
}

header nav ul li a {
  display: flex;
  text-decoration: none;
  color: rgb(241, 239, 239);
  font-family: "PT Sans", sans-serif;
  font-size: 1.2rem;
  padding: 1.8rem;
  transition: all 250ms linear 0s;
  text-align: center;
}

header nav ul li a:hover {
  font-size: 1.4rem;
  color: #fff;
}

header nav ul li a {
  animation-name: menu;
  animation-duration: 1s;
  transition: 100ms;
}

header nav ul li a .FiLogOut {
  position: relative;
  top: 2px;
  left: 2px;
}

.footer {
  background-color: #dcdcdc;
  color: #ffffff;
  text-align: center;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 200px;
  padding-left: 200px;
}

.social-icon {
  font-size: 24px;
  margin-right: 10px;
}

.icon-instagram {
  color: #c13584;
}

.icon-facebook {
  color: #3b5998;
}

.icon-envelope {
  color: #b23121;
}

.icon-whatsapp {
  color: #25d366;
}

.footer-middle {
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  color: #206ce4;
  margin-top: 10px;
}

.footer-right {
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  color: #206ce4;
  margin-bottom: 1em;
}

@keyframes menu {
  0% {
    left: 0px;
    top: -100px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}

@media (max-width: 630px) {
  html {
    font-size: 70%;
  }

  .hero .container {
    flex-direction: column;
    text-align: center;
  }

  .form-group {
    display: block;
  }
}

@media (max-width: 1120px) {
  .menu-section nav {
    display: none;
  }

  .container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    height: 65px;
    justify-content: space-between;
  }

  .one,
  .two,
  .three {
    background-color: #ffffff;
    height: 5px;
    width: 100%;
    margin: 6px auto;
    transition-duration: 0.3s;
  }

  .menu-toggle {
    vertical-align: middle;
    width: 40px;
    margin-right: 20px;
    display: inline-block;
    justify-content: flex-end;
  }

  /*Fullscreen*/
  .menu-section.on {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #206ce4;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-section.on nav {
    display: block;
  }

  .menu-section.on .menu-toggle {
    position: absolute;
    top: 25px;
    right: 20px;
    margin-left: auto;
  }

  .menu-section.on .menu-toggle .one {
    transform: rotate(45deg) translate(7px, 7px);
    background-color: #fff;
  }

  .menu-section.on .menu-toggle .two {
    opacity: 0;
  }

  .menu-section.on .menu-toggle .three {
    transform: rotate(-45deg) translate(8px, -9px);
    background-color: #fff;
  }

  .menu-section.on nav ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .menu-section.on nav ul li {
    color: #fff;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .menu-section.on nav ul a {
    transition-duration: 0.5s;
    display: block;
    color: #fff;
    padding: 1.8rem;
  }

  header nav ul li a .FiLogOut {
    width: 40px;
  }

  .menu-section.on nav ul .hide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm {
    max-width: none;
    width: 100%;
  }
}

@media (min-width: 1121px) {
  .menu-label {
    display: none;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 70%;
  }

  .menu-section.on .menu-toggle {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .menu-section.on nav ul li {
    opacity: 1;
  }

  .menu-section.on nav ul a {
    font-size: 2rem;
  }

  .footer {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 100px;
    padding-left: 100px;
  }

  .container,
  .container-sm {
    max-width: none;
    width: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    height: 65px;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .container,
  .container-sm {
    max-width: none;
    width: 100%;
  }

  html {
    font-size: 70%;
  }

  .menu-section.on .menu-toggle {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .menu-section.on nav ul li {
    opacity: 1;
  }

  .menu-section.on nav ul a {
    font-size: 2rem;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: none;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .menu-section.on .menu-toggle {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .menu-section.on nav ul li {
    opacity: 1;
  }

  .menu-section.on nav ul a {
    font-size: 2rem;
  }

  .footer {
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 425px) {
  .menu-section.on .menu-toggle {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .menu-section.on nav ul li {
    opacity: 1;
  }

  .menu-section.on nav ul a {
    font-size: 2rem;
  }
}

@media (max-width: 375px) {
  .menu-section.on .menu-toggle {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .menu-section.on nav ul li {
    opacity: 1;
  }

  .menu-section.on nav ul a {
    font-size: 2rem;
  }
}

@media (max-width: 320px) {
  .menu-section.on .menu-toggle {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .menu-section.on nav ul li {
    opacity: 1;
  }

  .menu-section.on nav ul a {
    font-size: 2rem;
  }
}
