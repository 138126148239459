/* CSS */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.title-depositions {
  font-family: "PT Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
}

.youtube-video {
  width: calc(33.33% - 30px);
  margin-bottom: 20px;
  padding: 0 15px 15px;
}

.video-wrapper {
  width: 100%;
  position: relative;
}

.youtube-video iframe {
  width: 100%;
  height: 300px;
}

@media (max-width: 768px) {
  .youtube-video {
    width: calc(50% - 30px);
  }
}

@media (max-width: 560px) {
  .youtube-video {
    width: calc(100% - 30px);
  }
}

@media (max-width: 425px) {
  .youtube-video {
    width: calc(100% - 30px);
  }
}
