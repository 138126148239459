@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ErroFonte&display=swap");
@import url("https://fonts.googleapis.com/css2?family=SuccessFont&display=swap");

.location-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  padding: 0px 90px 30px 90px;
}

.container-form {
  width: 100%;
}

.location-title {
  font-family: "PT Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.location-form {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.location-input {
  margin-bottom: 14px;
  height: 34px;
  border-radius: 4px;
  border: 0;
  padding: 0 8px;
  border: 1px solid #000;
  border-radius: 5px;
}

.location-textarea {
  margin-bottom: 14px;
  border-radius: 4px;
  border: 0;
  padding: 8px;
  height: 94px;
  resize: none;
  border: 1px solid #000;
  border-radius: 5px;
}

.location-button {
  height: 34px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  color: #fff;
  background-color: #206ce4;
  font-size: 18px;
  transition: background-color, transform 0.8s;
}

.location-button:hover {
  background-color: #0000ff;
  transform: scale(1.01);
}

.error-message {
  color: red;
  font-family: "ErroFonte", sans-serif;
  margin-top: -10px;
}

.success-message {
  color: green;
  font-family: "SuccessFont", sans-serif;
  margin-top: -10px;
}
.gmap_canvas {
  width: 100%;
  height: 600px;
}

.gmap_canvas iframe {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1420px) {
  .location-container {
    flex-direction: column-reverse;
    padding: 0px 30px 0px 30px;
  }

  .gmap_canvas {
    height: 400px;
  }

  .location-form {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 50px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .location-container {
    flex-direction: column-reverse;
    padding: 0px 30px 0px 30px;
  }

  .gmap_canvas {
    height: 400px;
  }

  .location-form {
    margin-top: 30px;
  }
}
