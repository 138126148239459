@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.banner {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  display: block;
}

.banner-content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.banner-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "PT Sans", sans-serif;
}

.banner-description {
  font-size: 18px;
  margin-bottom: 30px;
  font-family: "PT Sans", sans-serif;
}

.banner-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #206ce4;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.banner-button:hover {
  background-color: #0000cc;
}

@media screen and (max-width: 768px) {
  .banner-title {
    font-size: 28px;
    font-weight: bold;
  }

  .banner-description {
    font-size: 16px;
    font-weight: bold;
  }

  .banner-button {
    width: 40%;
  }

  .banner-content {
    font-size: 15px;
    top: 35%;
  }
  .banner-content {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .banner-title {
    font-size: 28px;
    font-weight: bold;
  }

  .banner-description {
    font-size: 20px;
    font-weight: bold;
  }
  .banner-content {
    top: 35%;
  }
  .banner-content {
    width: 80%;
  }
  .banner-button {
    font-size: 15px;
    width: 40%;
  }
}
